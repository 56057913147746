/* eslint-env jquery */
import React, { useState, useEffect } from "react";
import HeaderSape from "./assets/header-sape.png";
import SweetAppImage from "./assets/sweetapp4.png";
import FocusPic from "./assets/rocket.png";
import ConfidencePic from "./assets/king.png";
import SelfAwarePic from "./assets/mirror.png";
import ImpactfulPic from "./assets/influence3.png";
import ResillientPic from "./assets/atlas.png";
import PersonalPic from "./assets/apw-Icon1.png";
import EasyPic from "./assets/apw-Icon2.png";
import FunPic from "./assets/apw-Icon3.png";
import AffordablePic from "./assets/apw-Icon4.png";
import HappyPic from "./assets/happy.png";
import CalmnessPic from "./assets/calmness.png";
import Parallax from "parallax-js";
import * as EmailValidator from "email-validator";
import * as cheersAnimationData from "./assets/cheers.json";
import DeepLinkCheck from "./DeepLinkCheck";
import DeepLinkFeed from "./DeepLinkFeed";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Link,
} from "react-router-dom";

import { TransitionGroup, CSSTransition } from "react-transition-group";
// function TrueEmail(props) {
//   const validEmail = props.validEmail;
//   if (validEmail) {
//     return <div>Valid Email</div>;
//   }
//   return <div>Invalid Email</div>;
// }

function AppContent(props) {
  return (
    <TransitionGroup
      style={{
        height: "100%",
        // backgroundColor: "black",
      }}
    >
      <CSSTransition
        key={useLocation().pathname}
        classNames="fade"
        timeout={3000}
        style={{
          height: "100%",
        }}
      >
        <Routes location={useLocation()}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/check/:id" element={<DeepLinkCheck />} />
          <Route path="/feed/:id" element={<DeepLinkFeed />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
    // <Landing></Landing>
  );
}

class LandingPage extends React.Component {
  state = {
    email: "",
    validEmail: false,
    isStopped: true,
    emailSubmitted: false,
    //screensize
    width: window.innerWidth,
  };

  // onClick = () => {
  //   console.log(this.state.email);
  // }

  onInputChange = (value) => {
    var pro = this.setState({ email: value }, () => {
      this.setState({ validEmail: EmailValidator.validate(this.state.email) });
    });
  };

  onClickJoin = async () => {
    const input = {
      email: this.state.email,
    };

    $("#bite-66").modal("show");

    this.setState({ emailSubmitted: true });

    $("#bite-66").on("hidden.bs.modal", () => {
      console.log("close");
      this.setState({ isStopped: true, validEmail: false });
    });

    this.setState({ isStopped: false });
  };

  componentDidMount() {
    var scene = document.getElementsByClassName("parallax");
    var parallax = new Parallax(scene[0]);
    var parallax = new Parallax(scene[1]);
  }

  submitHandler = (e) => {
    e.preventDefault();
    console.log(e);
    if (this.state.validEmail) {
      this.onClickJoin();
    }
  };
  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: cheersAnimationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div>
        <main>
          <section
            className="slider-area slider-bg2 second-slider-bg d-flex fix"
            style={{
              backgroundImage:
                "url(" + require("./assets/pink-header-bg.png") + ")",
              backgroundPosition: "right 0",
              backgroundRepeat: "no-repeat",
              backgroundSize: "65%",
            }}
          >
            <div className="slider-shape ss-one layer parallax">
              <img
                data-depth="0.20"
                src={HeaderSape}
                alt="shape"
                style={{ zIndex: "-1 !important" }}
              ></img>
            </div>
            <div className="container action-space">
              <div className="row">
                <div className="col-lg-6">
                  <div className="slider-content second-slider-content left-center">
                    <ul className="small-title mb-30">
                      <li>New</li>
                      <li>AI Call app</li>
                    </ul>
                    <h2 data-animation="fadeInUp" data-delay=".4s">
                      <span>AI-Generated</span> Calls
                    </h2>
                    <div className="mt-30 mb-30">
                      {/* <a href="#" className="btn ss-btn" data-animation="fadeInUp" data-delay=".8s">Coming soon..</a> */}
                      <form
                        onSubmit={this.submitHandler}
                        className="contact-form"
                      >
                        <div
                          className="row"
                          style={
                            {
                              // display: "flex",
                              // maxWidth: "220px",
                              // justifyContent: "center",
                              // textAlign: "center",
                            }
                          }
                        >
                          {/* <div
                            className="col-lg-10"
                            style={{
                              //display potential values: flex, inline-flex, inline-block
                              display: "inline-block",
                              // backgroundColor: "green",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className="form-control-wrap"
                              style={{
                                textAlign: "center",
                                justifyContent: "center",
                                horizontalAlign: "center",
                                // maxWidth: "220px",
                                // width: "100%",
                              }}
                            >
                              <div
                                className="col-lg-12"
                                style={{
                                  justifyContent: "center",
                                  textAlign: "center",
                                  backgroundColor: "black",
                                  // maxWidth: "220px",
                                  //rounded border
                                  borderRadius: "30px",
                                  //drop shadow
                                  boxShadow: "0px 0px 10px 0px #000000",
                                }}
                              >
                                <a
                                  // className="text-center mt-20"
                                  href="https://play.google.com/store/apps/details?id=com.fashiongenie.fashiongenie&hl=en_US"
                                  target="_parent"
                                >
                                  <img
                                    className="mt-10 mb-10"
                                    src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/google_play_store.svg"
                                    border="0"
                                    style={{
                                      maxWidth: "200px",
                                    }}
                                  ></img>
                                </a>
                                <a
                                  // className="text-center mt-20 ml-5"
                                  href="https://apps.apple.com/us/app/outfit-check-ai-style-scanner/id6476243809"
                                  target="_parent"
                                >
                                  <img
                                    className="mt-10 mb-10"
                                    src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/ios_app_store.svg"
                                    border="0"
                                    style={{
                                      maxWidth: "200px",
                                      //if screen is big
                                      marginLeft:
                                        this.state.width > 768 ? "10px" : "0px",
                                    }}
                                  ></img>
                                </a>
                              </div>
                            </span>
                          </div> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 parallax">
                  {/* <img
                    data-depth="0.10"
                    src={SweetAppImage}
                    alt="shape"
                    className="s-img"
                    style={{ zIndex: "1 !important" }}
                  ></img> */}
                </div>
              </div>
            </div>
          </section>

          <div
            className="modal fade position-center-center m-width-370"
            id="bite-66"
            bite-show="fadeIn"
            bite-hide="fadeOut"
          >
            <div className="modal-dialog animated fast">
              <div className="modal-content">
                <div className="modal-body bg-white text-center">
                  <div className="popup-title">Cheers to a sweet life!</div>
                  <p className="popup-text" style={{ color: "#FF3494" }}>
                    {this.state.email} ✅
                  </p>
                  <p className="popup-text">
                    We are glad to have you with us. We will send you a special
                    invitation when we launch!{" "}
                  </p>
                  <p className="popup-text">
                    As a token of our appreciation for being our early user, you
                    will receive a special gift accompanying the invitation!
                  </p>
                  <p className="popup-text">
                    Reach out to us{" "}
                    <a
                      style={{ color: "#FF3494" }}
                      href="mailto:hello@quantumquiz.app"
                    >
                      here
                    </a>{" "}
                    if you have requests or ideas for us. We can't wait to help
                    you on your self-improvement journey 😇
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer
          class="footer-bg footer-p pt-60"
          style={{
            backgroundImage: "url(" + require("./assets/f-bg.png") + ")",
            backgroundPosition: "center top",
            backgroundSize: "auto",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div class="footer-top">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-3 col-sm-6">
                  <div class="footer-widget mb-30">
                    <div class="mt-15 logo-text">
                      <h2 style={{ color: "white" }}>CallsWithAI</h2>
                    </div>
                    <div class="footer-text mb-20 logo-text">
                      <p>Reach out and say hi:</p>
                      <p>hello@characterverse.ai</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="copyright-wrap text-center">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="copyright-text">
                    <p>&copy; 2024 CallsWithAI App. All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

class App extends React.Component {
  render() {
    return (
      <Router
        style={{
          height: "100%",
          backgroundColor: "black",
        }}
      >
        <AppContent />
      </Router>
    );
  }
}

export default App;
